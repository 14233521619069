<template>
  <div class="users-page">
    <b-tabs>
        <!-- <b-tab-item label="Смена пароля">
          <change-user-password></change-user-password>
        </b-tab-item> -->
        <b-tab-item :label="$t('title.users')">
          <create-simple-user></create-simple-user>
        </b-tab-item>

        <b-tab-item v-if="user_superadmin" :label="$t('users.admins')">
           <create-admin-user></create-admin-user>
        </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CreateSimpleUser from '../components/CreateSimpleUser'
import CreateAdminUser from '../components/CreateAdminUser'
import ChangeUserPassword from '../components/ChangeUserPassword'

export default {
  name: 'Users',
  components: {
    CreateSimpleUser,
    CreateAdminUser,
    ChangeUserPassword
  },
  data () {
    return {
      user_superadmin: false
    }
  },
  watch: {
    '$store.getters.user': function() {
      this.checkUserSupeadmin()
    }
  },
  created() {
    this.checkUserSupeadmin()
  },
  methods: {
    checkUserSupeadmin() {
      if(this.$store.getters.user) {
        this.$store.getters.user.permissions.forEach(element => {
          if(element == 'superadmin' ) this.user_superadmin = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.custom-title {
  font-size: 18px;
}
.users-page {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 200px;

  .b-tabs {
    height: 100%;

    .tab-item {
      height: calc(100% - 10px);
    }
  }
}
</style>
