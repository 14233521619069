<template>
  <div class="user-page">
    <div class="user-wrapper">
      <div class="alert animated none" :class="alert.show ? 'fadeInRight' : ''" v-show="alert.show">
        <div class="alert-close" @click="alert.show = !alert.show">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.88463 5.0001L9.81717 1.06756C10.0615 0.823448 10.0615 0.427359 9.81717 0.183243C9.57285 -0.0610811 9.17718 -0.0610811 8.93285 0.183243L5.00031 4.11578L1.06756 0.183243C0.82324 -0.0610811 0.427568 -0.0610811 0.183243 0.183243C-0.0610811 0.427359 -0.0610811 0.823448 0.183243 1.06756L4.11599 5.0001L0.183243 8.93264C-0.0610811 9.17676 -0.0610811 9.57285 0.183243 9.81697C0.305406 9.93892 0.465509 10 0.625404 10C0.785299 10 0.945402 9.93892 1.06756 9.81676L5.00031 5.88422L8.93285 9.81676C9.05501 9.93892 9.21512 10 9.37501 10C9.53491 10 9.69501 9.93892 9.81717 9.81676C10.0615 9.57264 10.0615 9.17655 9.81717 8.93244L5.88463 5.0001Z"
              fill="#ffffff"
            />
          </svg>
        </div>
        {{ alert.text }}
      </div>

      <b-modal :active.sync="auth_key.modal.show" class="auth-key-modal" :can-cancel="false">
        <div class="auth-key">
          <div class="custom-close-modal" @click="auth_key.modal.show = !auth_key.modal.show">
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
                fill="#2C3236"
              />
            </svg>
          </div>
          <div class="auth-key-loading" v-show="auth_key.modal.loading">
            <b-loading
              :is-full-page="false"
              :active.sync="auth_key.modal.loading"
              :can-cancel="false"
            ></b-loading>
          </div>
          <div class="auth-key-container">
            <b-input class="auth-key-input" v-model="auth_key.key"></b-input>
            <b-button type="is-primary" @click="copyAuthKey">Скопировать</b-button>
          </div>
        </div>
      </b-modal>

      <b-modal :active.sync="tabs_site" class="table-modal" :can-cancel="false">
        <div class="table-modal-site-table">
          <div class="custom-close-modal" @click="tabs_site = !tabs_site">
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
                fill="#2C3236"
              />
            </svg>
          </div>
          <p class="sites-title">Выберите сайты</p>
          <site-table
            @idSite="idSite"
            :options="{
              method: this.check_method,
              edit: this.data_edit_user.sites,
              created: this.modal_created.selected_sites,
              selectable: true
            }"
          ></site-table>
        </div>
      </b-modal>

      <b-modal :active.sync="confirm.show" class="confirm" :can-cancel="false">
        <div class="confirm-window">
          <div class="custom-close-modal" @click="confirm.show = !confirm.show">
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
                fill="#2C3236"
              />
            </svg>
          </div>
          <div class="confirm-wrapper">
            <div class="confirm-window-description">
              {{ confirm.text }}
              <div v-show="confirm.box_params">
                <b-button @click="confirm.button.tabsShow = true">Показать</b-button>
                <b-modal
                  :active.sync="confirm.button.tabsShow"
                  class="table-modal"
                  :can-cancel="false"
                >
                  <div class="table-modal-wrapper">
                    <div
                      class="custom-close-modal"
                      @click="confirm.button.tabsShow = !confirm.button.tabsShow"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
                          fill="#2C3236"
                        />
                      </svg>
                    </div>
                    <p class="title">Менеджеры будут удалены</p>
                    <div class="table-border">
                      <b-table
                        :data="confirm.box_params"
                        :paginated="true"
                        :pagination-simple="true"
                        :per-page="5"
                      >
                        <template slot-scope="props">
                          <b-table-column field="user.first_name" label="Имя менеджера">
                            {{ props.row.username }}
                          </b-table-column>
                          <b-table-column field="site" label="Сайты">
                            <div
                              v-for="(column, index) in props.row.sites"
                              :key="index"
                              :visible="column"
                            >
                              <a v-bind:href="column.domain" target="blank">{{ column.domain }}</a>
                            </div>
                          </b-table-column>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="confirm-window-buttons">
              <b-button type="is-success" @click="confirm.func(confirm.func.params)">{{
                confirm.button.success
              }}</b-button>
              <b-button type="is-danger" @click="confirm.show = false">{{
                confirm.button.danger
              }}</b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal :active.sync="modal_window" :can-cancel="false">
        <div class="modal-window">
          <div class="custom-close-modal" @click="modal_window = !modal_window">
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
                fill="#2C3236"
              />
            </svg>
          </div>

          <h2 class="custom-title">
            Изменить пользователя
          </h2>

          <div class="modal-window-edit">
            <div class="left-part">
              <b-tooltip
                label="Имя пользователя может состоять из английских букв в нижнем регистре, цифр и символов подчеркивания."
                size="is-large"
                multilined
              >
                <b-field
                  :label="$t('users.username')"
                  :type="modal_edit.username.type"
                  :message="modal_edit.username.message"
                >
                  <b-input v-model="data_edit_user.username"></b-input>
                </b-field>
              </b-tooltip>

              <div>
                <div class="passwword">
                  <span>Изменить пароль?</span>
                  <b-switch v-model="data_edit_user.password.change"></b-switch>
                </div>
                <form v-show="data_edit_user.password.change" class="passwords-block">
                  <b-field
                    class="password-block"
                    :label="$t('users.password')"
                    :type="modal_edit.password.type"
                    :message="modal_edit.password.message"
                  >
                    <b-input
                      :placeholder="$t('users.enter_password')"
                      type="password"
                      v-model="data_edit_user.password.enter"
                    ></b-input>
                  </b-field>

                  <b-field
                    :label="$t('users.re_password')"
                    :type="modal_edit.confirm_password.type"
                    :message="modal_edit.confirm_password.message"
                  >
                    <b-input
                      :placeholder="$t('users.confirm_password')"
                      type="password"
                      v-model="data_edit_user.password.confirm"
                    ></b-input>
                  </b-field>
                </form>
              </div>

              <div class="active-block">
                <b-switch v-model="data_edit_user.active">
                  Активность
                </b-switch>
              </div>
              <b-button type="is-primary" @click="validateEditUser">
                Изменить
              </b-button>
            </div>

            <div class="right-part">
              <b-field
                :label="$t('users.email')"
                :type="modal_edit.email.type"
                :message="modal_edit.email.message"
              >
                <b-input v-model="data_edit_user.email"></b-input>
              </b-field>

              <div class="select-wrapper">
                <b-field
                  :label="$t('users.roles')"
                  :type="modal_edit.selected_roles.type"
                  :message="modal_edit.selected_roles.message"
                >
                  <b-dropdown aria-role="list">
                    <template slot="trigger">
                      <div class="select-input">
                        <span v-if="!data_edit_user.roles.length">
                          {{ $t('users.select_roles') }}
                        </span>
                        <span
                          class="selectet-items"
                          v-else
                          v-for="(role, role_index) in data_edit_user.roles"
                          :key="role_index"
                        >
                          {{ role }}
                        </span>
                      </div>
                    </template>

                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectRoleEdit(role.value)"
                      v-for="(role, role_index) in roles"
                      :key="role_index"
                    >
                      {{ role.value }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>

                <b-field
                  v-if="modal_edit.owne_role"
                  :label="'Имя Owner'"
                  :type="modal_edit.selected_owner.type"
                  :message="modal_edit.selected_owner.message"
                >
                  <b-input v-model="data_edit_user.blockOwner.name"></b-input>
                </b-field>

                <b-field :label="$t('access_rights')">
                  <b-dropdown aria-role="list">
                    <template slot="trigger">
                      <div class="select-input">
                        <span v-if="modal_edit.selected_permissions.items.length === 0">
                          {{ $t('choose_access_rights') }}
                        </span>
                        <span
                          class="selectet-items"
                          v-else
                          v-for="(permission, index) in modal_edit.selected_permissions.items"
                          :key="index"
                        >
                          {{ permission }}
                        </span>
                      </div>
                    </template>

                    <b-dropdown-item
                      aria-role="listitem"
                      v-for="(permission, index) in $store.getters.users_permissions"
                      :key="index"
                      @click="selectPermissionForEditUser(permission)"
                      :class="
                        modal_edit.selected_permissions.items.includes(permission)
                          ? 'selected-item'
                          : ''
                      "
                    >
                      {{ permission }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>

              <div class="select-wrapper">
                <div class="select-table-sites">
                  <div class="select-wrapper">
                    <b-field
                      class="select-wrapper-site"
                      :type="modal_edit.selected_sites.type"
                      :message="modal_edit.selected_sites.message"
                    >
                      <b-button type="is-primary" @click="callTable('edit')" outlined
                        >Выбрать сайт</b-button
                      >
                    </b-field>
                  </div>

                  <div v-show="data_edit_user.sites.length">
                    <span class="custom-style-label">
                      Выбранные сайты
                    </span>
                    <b-table
                      :data="data_edit_user.sites"
                      :paginated="true"
                      :pagination-simple="true"
                      :per-page="5"
                    >
                      <template slot-scope="props">
                        <b-table-column field="user.first_name" label="Домен">
                          <a v-bind:href="props.row.domain" target="blank">
                            {{ props.row.domain }}
                          </a>
                        </b-table-column>
                        <b-table-column field="id" label="Удалить" width="40" centered>
                          <div class="tabs-icon-dell" @click="selectSiteEdit(props.row)">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.41502 7.99983L15.7068 1.70803C16.0977 1.31746 16.0977 0.683746 15.7068 0.293177C15.3159 -0.0977257 14.6829 -0.0977257 14.292 0.293177L8.00017 6.58498L1.70803 0.293177C1.31713 -0.0977257 0.68408 -0.0977257 0.293177 0.293177C-0.0977257 0.683746 -0.0977257 1.31746 0.293177 1.70803L6.58531 7.99983L0.293177 14.2916C-0.0977257 14.6822 -0.0977257 15.3159 0.293177 15.7065C0.488629 15.9016 0.744783 15.9993 1.0006 15.9993C1.25643 15.9993 1.51258 15.9016 1.70803 15.7062L8.00017 9.41435L14.292 15.7062C14.4874 15.9016 14.7436 15.9993 14.9994 15.9993C15.2552 15.9993 15.5114 15.9016 15.7068 15.7062C16.0977 15.3156 16.0977 14.6819 15.7068 14.2913L9.41502 7.99983Z"
                                fill="#2C3236"
                              />
                            </svg>
                          </div>
                        </b-table-column>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <div class="loading" v-show="loading">
        <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>
      </div>

      <b-field class="search-table" v-show="!loading" label="Поиск по таблице">
        <b-input type="text" v-model.trim="searchValue"> </b-input>
      </b-field>

      <b-table
        :data="filterUsersTable"
        :checkbox-position="'left'"
        v-show="!loading"
        :paginated="true"
        :pagination-simple="true"
        :pagination-position="'both'"
        :per-page="10"
      >
        <template slot-scope="data">
          <b-table-column field="name" width="40">
            <template slot="header">
              <p>Имя пользователя</p>
            </template>
            {{ data.row.username }}
          </b-table-column>

          <b-table-column field="role" label="Роль" width="40">
            <div v-for="(column, index) in data.row.roles" :key="index" :visible="column">
              {{ column }}
            </div>
          </b-table-column>

          <b-table-column field="role" label="Права доступа" width="40">
            <b-button @click="toggleShowPermissions(data.row)">{{
              data.row.showPermissions ? $t('hide') : $t('show')
            }}</b-button>
            <div
              v-show="data.row.showPermissions"
              v-for="(column, index) in data.row.permissions"
              :key="index"
              :visible="column"
            >
              {{ column }}
            </div>
          </b-table-column>

          <b-table-column field="site" label="Сайты" width="40">
            <div v-for="(column, index) in data.row.sites" :key="index" :visible="column">
              <a v-bind:href="column.domain" target="blank">{{ column.domain }}</a>
            </div>
          </b-table-column>

          <b-table-column field="active" label="Активность" width="40" :centered="true">
            <div v-if="data.row.active">
              <div class="tabs-icon-active">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <mask id="path-1-inside-1" fill="white">
                      <path
                        d="M7.99998 16.0002C12.4183 16.0002 16 12.4185 16 8.00023C16 3.58196 12.4183 0.000244141 7.99998 0.000244141C3.58171 0.000244141 0 3.58196 0 8.00023C0 12.4185 3.58171 16.0002 7.99998 16.0002Z"
                      />
                    </mask>
                    <path
                      d="M15 8.00023C15 11.8662 11.866 15.0002 7.99998 15.0002V17.0002C12.9705 17.0002 17 12.9708 17 8.00023H15ZM7.99998 15.0002C4.134 15.0002 1 11.8662 1 8.00023H-1C-1 12.9708 3.02943 17.0002 7.99998 17.0002V15.0002ZM1 8.00023C1 4.13424 4.134 1.00024 7.99998 1.00024V-0.999756C3.02943 -0.999756 -1 3.02967 -1 8.00023H1ZM7.99998 1.00024C11.866 1.00024 15 4.13424 15 8.00023H17C17 3.02967 12.9705 -0.999756 7.99998 -0.999756V1.00024Z"
                      fill="#23d160"
                      mask="url(#path-1-inside-1)"
                    />
                    <path
                      d="M5 7.01587C5.55228 7.01587 6 6.56815 6 6.01587C6 5.46358 5.55228 5.01587 5 5.01587C4.44772 5.01587 4 5.46358 4 6.01587C4 6.56815 4.44772 7.01587 5 7.01587Z"
                      fill="#23d160"
                    />
                    <path
                      d="M11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7Z"
                      fill="#23d160"
                    />
                    <path
                      d="M3.93658 10.5659C6.17236 12.8487 9.81941 12.8392 12.0422 10.5422C12.3254 10.2496 11.8792 9.80243 11.5957 10.0956C9.60821 12.1492 6.3836 12.1619 4.38315 10.1194C4.09778 9.82791 3.65147 10.2749 3.93658 10.5659Z"
                      fill="#23d160"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div v-else>
              <div class="tabs-icon-active">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <mask id="path-1-inside-1" fill="white">
                      <path
                        d="M7.99998 16.0002C12.4183 16.0002 16 12.4185 16 8.00023C16 3.58196 12.4183 0.000244141 7.99998 0.000244141C3.58171 0.000244141 0 3.58196 0 8.00023C0 12.4185 3.58171 16.0002 7.99998 16.0002Z"
                      />
                    </mask>
                    <path
                      d="M15 8.00023C15 11.8662 11.866 15.0002 7.99998 15.0002V17.0002C12.9705 17.0002 17 12.9708 17 8.00023H15ZM7.99998 15.0002C4.134 15.0002 1 11.8662 1 8.00023H-1C-1 12.9708 3.02943 17.0002 7.99998 17.0002V15.0002ZM1 8.00023C1 4.13424 4.134 1.00024 7.99998 1.00024V-0.999756C3.02943 -0.999756 -1 3.02967 -1 8.00023H1ZM7.99998 1.00024C11.866 1.00024 15 4.13424 15 8.00023H17C17 3.02967 12.9705 -0.999756 7.99998 -0.999756V1.00024Z"
                      fill="#ff3860"
                      mask="url(#path-1-inside-1)"
                    />
                    <path
                      d="M5 7.01587C5.55228 7.01587 6 6.56815 6 6.01587C6 5.46358 5.55228 5.01587 5 5.01587C4.44772 5.01587 4 5.46358 4 6.01587C4 6.56815 4.44772 7.01587 5 7.01587Z"
                      fill="#ff3860"
                    />
                    <path
                      d="M11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7Z"
                      fill="#ff3860"
                    />
                    <path
                      d="M12.0412 11.7056C9.80545 9.4228 6.1584 9.43229 3.93562 11.7293C3.6524 12.0219 4.09857 12.4691 4.38216 12.1759C6.3696 10.1222 9.59422 10.1096 11.5947 12.1521C11.88 12.4436 12.3263 11.9966 12.0412 11.7056Z"
                      fill="#ff3860"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </b-table-column>

          <b-table-column field="key" label="Ссылка для входа" width="200" :centered="true">
            <div class="tabs-icon-key" @click="userKey(data.row)">
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0231 8.38138C10.9522 8.22475 10.7965 8.125 10.625 8.125H8.87499V2.4375C8.87499 2.196 8.67899 2 8.43749 2H6.68749C6.44599 2 6.24999 2.196 6.24999 2.4375V8.125H4.49999C4.32849 8.125 4.17274 8.22563 4.10187 8.38138C4.03012 8.538 4.05812 8.72087 4.17099 8.85038L7.23349 12.3504C7.31662 12.4458 7.43649 12.5 7.56249 12.5C7.68849 12.5 7.80837 12.4449 7.89149 12.3504L10.954 8.85038C11.0677 8.72175 11.094 8.538 11.0231 8.38138Z"
                  fill="#2C3236"
                />
                <path
                  d="M13.25 11.625C12.7668 11.625 12.375 12.0168 12.375 12.5V13.5938C12.375 13.9562 12.0812 14.25 11.7188 14.25H3.40625C3.04381 14.25 2.75 13.9562 2.75 13.5938V12.5C2.75 12.0168 2.35825 11.625 1.875 11.625C1.39175 11.625 1 12.0168 1 12.5V15.125C1 15.6089 1.392 16 1.875 16H13.25C13.7339 16 14.125 15.6089 14.125 15.125V12.5C14.125 12.0168 13.7332 11.625 13.25 11.625Z"
                  fill="#2C3236"
                />
              </svg>
            </div>
          </b-table-column>

          <b-table-column field="edit" label="Редактирование" width="40" :centered="true">
            <div class="tabs-icon-edit" @click="edit_user(data.row)">
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.28606 13.9568C1.28606 13.6806 1.50991 13.4568 1.78606 13.4568H13.9466C14.2227 13.4568 14.4466 13.6806 14.4466 13.9568C14.4466 14.2329 14.2227 14.4568 13.9466 14.4568H1.78606C1.50991 14.4568 1.28606 14.2329 1.28606 13.9568Z"
                  fill="#2C3236"
                />
                <path
                  d="M9.56521 2.36876C9.76097 2.174 10.0784 2.174 10.2741 2.36876L11.6908 3.77825C11.8866 3.97301 11.8866 4.28878 11.6908 4.48354L4.10197 12.0337C4.04451 12.0908 3.97394 12.1332 3.89635 12.1572L1.98441 12.7487C1.61101 12.8642 1.25437 12.5288 1.34928 12.1514L1.85516 10.1399C1.8772 10.0522 1.92277 9.97216 1.98699 9.90827L9.56521 2.36876Z"
                  fill="#2C3236"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.2911 2.11478L11.8744 0.705294L11.1502 1.42581L12.5669 2.8353L13.2911 2.11478ZM12.2288 0.352647C12.0331 0.157885 11.7157 0.157886 11.5199 0.352647L10.7957 1.07317C10.5999 1.26793 10.5999 1.5837 10.7957 1.77846L12.2124 3.18795C12.4082 3.38271 12.7256 3.38271 12.9213 3.18795L13.6456 2.46743C13.8413 2.27267 13.8413 1.9569 13.6456 1.76214L12.2288 0.352647Z"
                  fill="#2C3236"
                />
              </svg>
            </div>
          </b-table-column>

          <b-table-column field="dell" label="Удаление" width="40" :centered="true">
            <div class="tabs-icon-dell" @click="questionConfirm('dell', data.row.id)">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.41502 7.99983L15.7068 1.70803C16.0977 1.31746 16.0977 0.683746 15.7068 0.293177C15.3159 -0.0977257 14.6829 -0.0977257 14.292 0.293177L8.00017 6.58498L1.70803 0.293177C1.31713 -0.0977257 0.68408 -0.0977257 0.293177 0.293177C-0.0977257 0.683746 -0.0977257 1.31746 0.293177 1.70803L6.58531 7.99983L0.293177 14.2916C-0.0977257 14.6822 -0.0977257 15.3159 0.293177 15.7065C0.488629 15.9016 0.744783 15.9993 1.0006 15.9993C1.25643 15.9993 1.51258 15.9016 1.70803 15.7062L8.00017 9.41435L14.292 15.7062C14.4874 15.9016 14.7436 15.9993 14.9994 15.9993C15.2552 15.9993 15.5114 15.9016 15.7068 15.7062C16.0977 15.3156 16.0977 14.6819 15.7068 14.2913L9.41502 7.99983Z"
                  fill="#2C3236"
                />
              </svg>
            </div>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                {{ $t('no_data') }}
              </p>
            </div>
          </section>
        </template>
      </b-table>

      <b-button
        class="request-button"
        type="is-primary"
        @click="create_user_wrapper = !create_user_wrapper"
        v-if="!loading"
      >
        Добавить пользователя
      </b-button>
    </div>

    <div class="create-user-wrapper" v-show="create_user_wrapper">
      <h2 class="custom-title">
        {{ $t('users.create_user') }}
      </h2>

      <div class="new-data-blocks">
        <div class="left-part">
          <b-tooltip
            label="Имя пользователя может состоять из английских букв в нижнем регистре, цифр и символов подчеркивания."
            size="is-large"
            multilined
          >
            <b-field
              :label="$t('users.username')"
              :type="modal_created.username.type"
              :message="modal_created.username.message"
            >
              <b-input
                :placeholder="$t('users.enter_username')"
                v-model="modal_created.username.value"
              ></b-input>
            </b-field>
          </b-tooltip>
          <form class="passwords-block">
            <b-field
              class="password-block"
              :label="$t('users.password')"
              :type="modal_created.password.type"
              :message="modal_created.password.message"
            >
              <b-input
                :placeholder="$t('users.enter_password')"
                type="password"
                v-model="modal_created.password.value"
              ></b-input>
            </b-field>

            <b-field
              :label="$t('users.re_password')"
              :type="modal_created.confirm_password.type"
              :message="modal_created.confirm_password.message"
            >
              <b-input
                :placeholder="$t('users.confirm_password')"
                type="password"
                v-model="modal_created.confirm_password.value"
              ></b-input>
            </b-field>
          </form>
        </div>

        <div class="right-part">
          <b-field
            :label="$t('users.email')"
            :type="modal_created.email.type"
            :message="modal_created.email.message"
          >
            <b-input
              :placeholder="$t('users.enter_email')"
              v-model="modal_created.email.value"
            ></b-input>
          </b-field>

          <div class="select-wrapper">
            <b-field
              :label="$t('users.roles')"
              :type="modal_created.selected_roles.type"
              :message="modal_created.selected_roles.message"
            >
              <b-dropdown aria-role="list">
                <template slot="trigger">
                  <div class="select-input">
                    <span v-if="modal_created.selected_roles.items.length === 0">
                      {{ $t('users.select_roles') }}
                    </span>
                    <span
                      class="selectet-items"
                      v-else
                      v-for="(role, role_index) in modal_created.selected_roles.items"
                      :key="role_index"
                    >
                      {{ role }}
                    </span>
                  </div>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  :class="
                    modal_created.selected_roles.items.includes(role.value) ? 'selected-item' : ''
                  "
                  @click="selectRole(role.value)"
                  v-for="(role, role_index) in roles"
                  :key="role_index"
                >
                  {{ role.value }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>

            <b-field :label="$t('access_rights')">
              <b-dropdown aria-role="list" :scrollable="true">
                <template slot="trigger">
                  <div class="select-input">
                    <span v-if="modal_created.selected_permissions.items.length === 0">
                      {{ $t('choose_access_rights') }}
                    </span>
                    <span
                      class="selectet-items"
                      v-else
                      v-for="(permission, index) in modal_created.selected_permissions.items"
                      :key="index"
                    >
                      {{ permission }}
                    </span>
                  </div>
                </template>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="selectAllPermissionForNewUser()"
                >
                  {{ this.modal_created.selected_permissions.items.length ? 'Удалить все' : 'Выбрать все' }}
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  v-for="(permission, index) in $store.getters.users_permissions"
                  :key="index"
                  @click="selectPermissionForNewUser(permission)"
                  :class="
                    modal_created.selected_permissions.items.includes(permission)
                      ? 'selected-item'
                      : ''
                  "
                >
                  {{ permission }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>

            <b-field v-if="modal_created.blockOwner.active" :label="'Имя Owner'">
              <b-input v-model="modal_created.blockOwner.name"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="select-wrapper">
        <div class="select-table-sites">
          <div class="select-wrapper">
            <b-field
              class="select-wrapper-site"
              :type="modal_created.selected_sites.type"
              :message="modal_created.selected_sites.message"
            >
              <b-button type="is-primary" @click="callTable('created')" outlined
                >Выбрать сайт</b-button
              >
            </b-field>
          </div>

          <div v-show="modal_created.selected_sites.items.length">
            <span class="custom-style-label">Выбранные сайты</span>
            <b-table
              :data="modal_created.selected_sites.items"
              :paginated="true"
              :pagination-simple="true"
              :per-page="5"
            >
              <template slot-scope="props">
                <b-table-column field="user.first_name" label="Домен">
                  <a v-bind:href="props.row.domain" target="blank">{{ props.row.domain }}</a>
                </b-table-column>
                <b-table-column label="Тариф">
                  <b-dropdown aria-role="list">
                    <template slot="trigger">
                      <div class="select-input">
                        <span v-if="props.row.tariff_id">
                          {{ tariffs.find(t => t.id === props.row.tariff_id).name }}
                        </span>
                        <span v-else>
                          {{ $t('tariffs.choose_tariff') }}
                        </span>
                      </div>
                    </template>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="selectSiteTariff(tariff, props.row)"
                      v-for="(tariff, index) in tariffs"
                      :key="index"
                    >
                      {{ tariff.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-table-column>
                <b-table-column field="id" label="Удалить" width="40" centered>
                  <div class="tabs-icon-dell" @click="selectSite(props.row)">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.41502 7.99983L15.7068 1.70803C16.0977 1.31746 16.0977 0.683746 15.7068 0.293177C15.3159 -0.0977257 14.6829 -0.0977257 14.292 0.293177L8.00017 6.58498L1.70803 0.293177C1.31713 -0.0977257 0.68408 -0.0977257 0.293177 0.293177C-0.0977257 0.683746 -0.0977257 1.31746 0.293177 1.70803L6.58531 7.99983L0.293177 14.2916C-0.0977257 14.6822 -0.0977257 15.3159 0.293177 15.7065C0.488629 15.9016 0.744783 15.9993 1.0006 15.9993C1.25643 15.9993 1.51258 15.9016 1.70803 15.7062L8.00017 9.41435L14.292 15.7062C14.4874 15.9016 14.7436 15.9993 14.9994 15.9993C15.2552 15.9993 15.5114 15.9016 15.7068 15.7062C16.0977 15.3156 16.0977 14.6819 15.7068 14.2913L9.41502 7.99983Z"
                        fill="#2C3236"
                      />
                    </svg>
                  </div>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <b-button class="request-button" type="is-primary" @click="validateCreateUser">
        {{ $t('add') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import SiteTable from '../components/SiteTable'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateSimpleUser',
  components: {
    SiteTable
  },
  data() {
    return {
      auth_key: {
        modal: {
          show: false,
          loading: true
        },
        key: ''
      },
      get_users: {
        role: [],
        sites: []
      },
      check_method: null,
      arr_site: [],
      tabs_site: false,
      confirm: {
        box_params: null,
        answer: false,
        show: false,
        text: '',
        button: {
          success: 'OK',
          danger: 'NO',
          tabsShow: false
        }
      },
      alert: {
        show: false,
        text: 'text'
      },
      data_edit_user: {
        sites: [],
        roles: [],
        password: {
          change: false,
          enter: null,
          confirm: null
        },
        active: ''
      },
      modal_edit: {
        owne_role: false,
        username: {},
        password: {},
        confirm_password: {},
        email: {},
        selected_roles: {},
        selected_permissions: {
          items: []
        },
        selected_sites: {},
        selected_owner: {}
      },
      modal_created: {
        username: {
          value: '',
          type: '',
          message: ''
        },
        password: {
          value: '',
          type: '',
          message: ''
        },
        confirm_password: {
          value: '',
          type: '',
          message: ''
        },
        email: {
          value: '',
          type: '',
          message: ''
        },
        selected_sites: {
          items: [],
          type: '',
          message: ''
        },
        selected_roles: {
          items: [],
          type: '',
          message: ''
        },
        selected_permissions: {
          items: []
        },
        blockOwner: {
          active: false,
          name: null
        }
      },
      table_modal: true,
      owner_user_created: false,
      modal_window: false,
      loading: true,
      users_array: [],
      create_user_wrapper: false,
      roles: [{ value: 'manager' }, { value: 'owner' }],
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters(['tariffs']),
    filterUsersTable() {
      return this.users_array.filter(el => {
        if (
          el.username
            .toLowerCase()
            .trim()
            .includes(this.searchValue.toLowerCase())
        ) {
          return el
        }

        if (el.sites.some(site => site.domain.includes(this.searchValue.toLowerCase()))) {
          return el
        }
      })
    }
  },
  async created() {
    await this.$store.dispatch('tariffs', { vm: { request_params: { page: 1, per_page: 1000 } } })
    this.getUserData()
  },
  methods: {
    selectSiteTariff(tariff, site) {
      console.log(tariff, site)
      console.log(this.modal_created.selected_sites.items)
      this.modal_created.selected_sites.items = this.modal_created.selected_sites.items.map(el => {
        if (el.id === site.id) {
          el.tariff_id = tariff.id
        }

        return el
      })
    },
    toggleShowPermissions(row) {
      this.users_array = this.users_array.map(el => {
        if (el.id === row.id) {
          el.showPermissions = !el.showPermissions
        }

        return el
      })
    },
    userKey(data) {
      this.auth_key.key = ''
      this.auth_key.modal.show = true
      this.auth_key.modal.loading = true

      this.$store.dispatch('getAuthKey', {
        id: data.id,
        vue: this,
        callback: this.callBackUserkey,
        callbackerror: this.callbackerror
      })
    },
    callBackUserkey(data) {
      this.auth_key.modal.loading = false
      this.auth_key.key = 'https://cp.leadhit.io/#/authorization/' + data.body.auth_key
      document.querySelector('.auth-key-input input').value = this.auth_key.key
      this.copyAuthKey()
      this.alertNotification('Ссылка скопирована')
    },
    copyAuthKey() {
      document.querySelector('.auth-key-input input').select()
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
    },
    callTable(method) {
      this.tabs_site = true
      this.check_method = method
    },
    idSite(params) {
      if (this.check_method == 'created') {
        this.selectSite(params.params)
      } else if ((this.check_method = 'edit')) {
        this.selectSiteEdit(params.params)
      }
    },
    questionConfirm(act, params) {
      this.confirm.box_params = ''
      if (act === 'dell') {
        this.$store.getters.all_users[params].roles.forEach(element => {
          if (element == 'owner') {
            var ownerOfManager = []
            this.users_array.forEach(element => {
              if (element.owner == params) {
                ownerOfManager.push(element)
              }
            }, this)
            if (ownerOfManager.length) {
              this.confirm.box_params = ownerOfManager
              this.confirm.text =
                'Вы уверены что хотите удалить пользователя и закрепленных за ним менеджеров?'
            } else {
              this.confirm.text = 'Вы уверены что хотите удалить пользователя?'
            }
          } else {
            this.confirm.text = 'Вы уверены что хотите удалить пользователя?'
          }
        })
        this.confirm.show = true
        this.confirm.func = this.dell_user
        this.confirm.func.params = params
      } else if (act === 'created') {
        this.confirm.show = true
        this.confirm.text = 'Вы уверены что хотите создать пользователя?'

        this.confirm.func = this.requestCreateUser
      } else if (act === 'edit') {
        this.confirm.show = true
        this.confirm.text = 'Вы уверены что хотите изменить пользователя?'

        this.confirm.func = this.requestEditUser
      }
    },
    callbackerror(resolve, error) {
      if (resolve) {
        resolve(true)
      }
      this.alertNotification(error)
    },
    alertNotification(text) {
      this.alert.show = true
      this.alert.text = text

      setTimeout(() => {
        this.alert.show = false
      }, 3500),
        this
    },
    clear_modal_edit() {
      this.modal_edit = {
        username: {
          value: '',
          type: '',
          message: ''
        },
        password: {
          value: '',
          type: '',
          message: ''
        },
        confirm_password: {
          value: '',
          type: '',
          message: ''
        },
        email: {
          value: '',
          type: '',
          message: ''
        },
        selected_roles: {
          items: [],
          type: '',
          message: ''
        },
        selected_permissions: {
          items: []
        },
        selected_sites: {
          items: [],
          type: '',
          message: ''
        },
        selected_owner: {
          items: [],
          type: '',
          message: ''
        },
        active: '',
        owner_id: ''
      }
    },
    formatGettersforArray() {
      let string = []
      for (var key in this.$store.getters.all_users) {
        string.push(this.$store.getters.all_users[key])
      }
      this.loading = false
      string = JSON.stringify(string)
      this.users_array = JSON.parse(string)
    },
    validatePassword(pass1, pass2, type) {
      if (pass1 && pass1.length > 0 && pass2 && pass2.length > 0) {
        if (pass1 === pass2) {
          if (type === 'edit') {
            this.modal_edit.password.type = ''
            this.modal_edit.password.message = ''

            this.modal_edit.confirm_password.type = ''
            this.modal_edit.confirm_password.message = ''
            return true
          } else if (type === 'created') {
            this.modal_created.password.type = ''
            this.modal_created.password.message = ''

            this.modal_created.confirm_password.type = ''
            this.modal_created.confirm_password.message = ''
            return true
          }
        } else {
          if (type === 'edit') {
            this.modal_edit.password.type = 'is-danger'
            this.modal_edit.password.message = 'Пароли не совпали'

            this.modal_edit.confirm_password.type = 'is-danger'
            this.modal_edit.confirm_password.message = 'Пароли не совпали'
            return false
          } else if (type === 'created') {
            this.modal_created.confirm_password.type = 'is-danger'
            this.modal_created.confirm_password.message = 'Пароли не совпали'

            this.modal_created.password.type = 'is-danger'
            this.modal_created.password.message = 'Пароли не совпали'
            return false
          }
        }
      } else {
        if (type === 'edit') {
          this.modal_edit.password.type = 'is-danger'
          this.modal_edit.password.message = this.$t('users.enter_password')

          this.modal_edit.confirm_password.type = 'is-danger'
          this.modal_edit.confirm_password.message = this.$t('users.enter_password')
          return false
        } else if (type === 'created') {
          this.modal_created.confirm_password.type = 'is-danger'
          this.modal_created.confirm_password.message = this.$t('users.enter_password')

          this.modal_created.password.type = 'is-danger'
          this.modal_created.password.message = this.$t('users.enter_password')
          return false
        }
      }
    },
    validateUsername(name, type) {
      if (
        name.length > 0 &&
        !!name.match(this.$store.getters.regex.username) &&
        name == name.toLowerCase()
      ) {
        if (type === 'edit') {
          this.modal_edit.username.type = ''
          this.modal_edit.username.message = ''
          return true
        } else if (type === 'created') {
          this.modal_created.username.type = ''
          this.modal_created.username.message = ''
          return true
        }
      } else {
        if (type === 'edit') {
          this.modal_edit.username.type = 'is-danger'
          this.modal_edit.username.message = this.$t('users.this_username_invalid')
          return false
        } else if (type === 'created') {
          this.modal_created.username.type = 'is-danger'
          this.modal_created.username.message = this.$t('users.this_username_invalid')
          return false
        }
      }
    },
    validateEmail(email, type) {
      if (email.length > 0 && !!email.match(this.$store.getters.regex.email)) {
        if (type === 'edit') {
          this.modal_edit.email.type = ''
          this.modal_edit.email.message = ''
          return true
        } else if (type === 'created') {
          this.modal_created.email.type = ''
          this.modal_created.email.message = ''
          return true
        }
      } else {
        if (type === 'edit') {
          this.modal_edit.email.type = 'is-danger'
          this.modal_edit.email.message = this.$t('users.this_email_invalid')
          return false
        } else if (type === 'created') {
          this.modal_created.email.type = 'is-danger'
          this.modal_created.email.message = this.$t('users.this_email_invalid')
          return false
        }
      }
    },
    validateRoles(role, type) {
      if (role.length > 0) {
        if (type === 'edit') {
          this.modal_edit.selected_roles.type = ''
          this.modal_edit.selected_roles.message = ''
          return true
        } else if (type === 'created') {
          this.modal_created.selected_roles.type = ''
          this.modal_created.selected_roles.message = ''
          return true
        }
      } else {
        if (type === 'edit') {
          this.modal_edit.selected_roles.type = 'is-danger'
          this.modal_edit.selected_roles.message = this.$t('users.select_role')
          return false
        } else if (type === 'created') {
          this.modal_created.selected_roles.type = 'is-danger'
          this.modal_created.selected_roles.message = this.$t('users.select_role')
          return false
        }
      }
    },
    validateSites(site, type) {
      if (site.length > 0) {
        if (type === 'edit') {
          this.modal_edit.selected_sites.type = ''
          this.modal_edit.selected_sites.message = ''
          return true
        } else if (type === 'created') {
          this.modal_created.selected_sites.type = ''
          this.modal_created.selected_sites.message = ''
          return true
        }
      } else {
        if (type === 'edit') {
          // this.modal_edit.selected_sites.type = 'is-danger'
          // this.modal_edit.selected_sites.message = this.$t('users.select_site')
          // return false
          return true
        } else if (type === 'created') {
          this.modal_created.selected_sites.type = 'is-danger'
          this.modal_created.selected_sites.message = this.$t('users.select_site')
          return false
        }
      }
    },
    validateOwnerEdit(blockOwner, type) {
      if (!blockOwner.active) {
        return true
      }
      if (blockOwner.name === null || blockOwner.name.trim().length <= 0) {
        if (type === 'edit') {
          this.modal_edit.selected_owner.type = 'is-danger'
          this.modal_edit.selected_owner.message = 'Укажите имя Owner'
        } else if (type === 'created') {
        }
        return false
      } else {
        var defined = false
        Object.keys(this.$store.getters.all_users).forEach(element => {
          if (this.$store.getters.all_users[element].username == blockOwner.name) {
            if (type === 'edit') {
              this.data_edit_user.owner = this.$store.getters.all_users[element].id
              this.modal_edit.selected_owner.type = ''
              this.modal_edit.selected_owner.message = ''
              defined = true
            } else if (type === 'created') {
              this.modal_created.owner = this.$store.getters.all_users[element].id
              defined = true
            }
          }
        }, this)
        if (defined) {
          return true
        } else {
          if (type === 'edit') {
            this.modal_edit.selected_owner.type = 'is-danger'
            this.modal_edit.selected_owner.message = 'Такого Owner нету'
          } else if (type === 'created') {
          }
        }
      }
    },
    edit_user(editID) {
      this.data_edit_user_initial = null
      this.clear_modal_edit()
      let parse = JSON.stringify(editID)
      parse = JSON.parse(parse)

      parse['password'] = {
        change: false,
        enter: null,
        confirm: null
      }
      parse['blockOwner'] = {
        active: true,
        name: parse.owner ? this.$store.getters.all_users[parse.owner].username : null
      }

      this.data_edit_user = parse
      this.modal_window = true
      this.checkowne_role()

      if (parse.owner) {
        this.modal_edit.owner_id = parse.owner
      }

      this.modal_edit.email.value = parse.email
      this.modal_edit.username.value = parse.username
      this.modal_edit.active = parse.active
      parse.roles.forEach(element => {
        this.modal_edit.selected_roles.items.push(element)
      })
      this.modal_edit.selected_permissions.items = parse.permissions.map(item => {
        return item
      })
      parse.sites.forEach(element => {
        this.modal_edit.selected_sites.items.push(element.id)
      })
    },
    selectSiteEdit(site_obj) {
      if (this.data_edit_user.sites.length) {
        let checkRepeatSite = true
        this.data_edit_user.sites.forEach(element => {
          if (element.id.includes(site_obj.id)) {
            this.data_edit_user.sites = this.data_edit_user.sites.filter(function(site) {
              if (site.id !== site_obj.id) {
                return true
              } else {
                checkRepeatSite = false
              }
            })
          }
        }, this)
        if (checkRepeatSite) {
          this.data_edit_user.sites.push(site_obj)
        }
      } else {
        this.data_edit_user.sites.push(site_obj)
      }
      this.validateSites(this.data_edit_user.sites, 'edit')
    },
    selectRoleEdit(role_name) {
      if (this.data_edit_user.roles.includes(role_name)) {
        this.data_edit_user.roles = this.data_edit_user.roles.filter(function(role) {
          if (role !== role_name) return true
        })
      } else {
        this.data_edit_user.roles.push(role_name)
      }
      this.checkowne_role()
      this.validateRoles(this.data_edit_user.roles, 'edit')
    },
    selectRole(role_name) {
      if (this.modal_created.selected_roles.items.includes(role_name)) {
        this.modal_created.selected_roles.items = this.modal_created.selected_roles.items.filter(
          function(role) {
            if (role !== role_name) return true
          }
        )
      } else {
        this.modal_created.selected_roles.items.push(role_name)
      }
      var owneruserCreated = true

      this.modal_created.selected_roles.items.forEach(element => {
        if (element == 'owner') {
          owneruserCreated = false
        }
      })

      if (owneruserCreated) {
        this.modal_created.blockOwner.active = true
      } else {
        this.modal_created.blockOwner.active = false
      }
      this.validateRoles(this.modal_created.selected_roles.items, 'created')
    },
    selectedSitesClass(site_name) {
      return this.modal_created.selected_sites.items.includes(site_name) ? 'selected-item' : ''
    },
    selectSite(site_obj) {
      if (this.modal_created.selected_sites.items.length) {
        let checkRepeatSite = true
        this.modal_created.selected_sites.items.forEach(element => {
          if (element.id.includes(site_obj.id)) {
            this.modal_created.selected_sites.items = this.modal_created.selected_sites.items.filter(
              function(site) {
                if (site.id !== site_obj.id) {
                  return true
                } else {
                  checkRepeatSite = false
                }
              }
            )
          }
        }, this)
        if (checkRepeatSite) {
          this.modal_created.selected_sites.items.push(site_obj)
        }
      } else {
        this.modal_created.selected_sites.items.push(site_obj)
      }
      this.validateSites(this.modal_created.selected_sites.items, 'created')
    },
    checkowne_role() {
      this.modal_edit.owne_role = true
      var roleOwner = true
      this.data_edit_user.roles.forEach(element => {
        if (element === 'owner') {
          roleOwner = false
          this.modal_edit.owne_role = false
        }
        this.data_edit_user.blockOwner.active = roleOwner
      }, this)
    },
    checkNeedRequestEditUser() {
      if (
        this.modal_edit.email.value == this.data_edit_user.email &&
        this.modal_edit.username.value == this.data_edit_user.username &&
        this.modal_edit.active == this.data_edit_user.active &&
        !this.data_edit_user.password.change
      ) {
        var checkSite = []
        this.data_edit_user.sites.forEach(element => {
          checkSite.push(element.id)
        })

        var checkRoles = []
        this.data_edit_user.roles.forEach(element => {
          checkRoles.push(element)
        })

        if (
          !checkSite.filter(n => this.modal_edit.selected_sites.items.indexOf(n) === -1).length &&
          !this.modal_edit.selected_sites.items.filter(n => checkSite.indexOf(n) === -1).length
        ) {
          if (
            !checkRoles.filter(n => this.modal_edit.selected_roles.items.indexOf(n) === -1)
              .length &&
            !this.modal_edit.selected_roles.items.filter(n => checkRoles.indexOf(n) === -1).length
          ) {
            if (
              this.modal_edit.selected_permissions.length !== this.data_edit_user.permissions.length
            ) {
              return true
            } else {
              let index_permission = this.data_edit_user.permissions.findIndex(function(perm) {
                if (!this.modal_edit.selected_permissions.includes(perm)) return true
                else return false
              })
              if (index_permission !== -1) {
                return true
              } else {
                if (this.modal_edit.owner_id) {
                  if (
                    this.$store.getters.all_users[this.modal_edit.owner_id].username ==
                    this.data_edit_user.blockOwner.name
                  ) {
                    return false
                  } else {
                    return true
                  }
                } else {
                  return false
                }
              }
            }
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    },
    selectPermissionForNewUser(permission) {
      if (this.modal_created.selected_permissions.items.includes(permission)) {
        this.modal_created.selected_permissions.items = this.modal_created.selected_permissions.items.filter(
          function(perm) {
            if (perm === permission) return false
            else return true
          }
        )
      } else {
        this.modal_created.selected_permissions.items.push(permission)
      }
    },

    selectAllPermissionForNewUser() {

      if(this.modal_created.selected_permissions.items.length) {
        this.$store.getters.users_permissions.forEach((permission) => {
          if(this.modal_created.selected_permissions.items.includes(permission)) {
            return
          } else {
            this.modal_created.selected_permissions.items.push(permission)
          }
        })
      } 
      if( this.$store.getters.users_permissions.length === this.modal_created.selected_permissions.items.length) {
        this.modal_created.selected_permissions.items = []
      } else {
        this.$store.getters.users_permissions.forEach((permission) => this.modal_created.selected_permissions.items.push(permission))
      }
      
    },
    selectPermissionForEditUser(permission) {
      if (this.modal_edit.selected_permissions.items.includes(permission)) {
        this.modal_edit.selected_permissions.items = this.modal_edit.selected_permissions.items.filter(
          function(perm) {
            if (perm === permission) return false
            else return true
          }
        )
      } else {
        this.modal_edit.selected_permissions.items.push(permission)
      }
    },
    validateEditUser() {
      if (this.checkNeedRequestEditUser()) {
        var validation = true
        if (this.data_edit_user.password.change) {
          this.validatePassword(
            this.data_edit_user.password.enter,
            this.data_edit_user.password.confirm,
            'edit'
          )
            ? undefined
            : (validation = false)
        }
        this.validateUsername(this.data_edit_user.username, 'edit')
          ? undefined
          : (validation = false)
        this.validateEmail(this.data_edit_user.email, 'edit') ? undefined : (validation = false)
        this.validateRoles(this.data_edit_user.roles, 'edit') ? undefined : (validation = false)
        this.validateSites(this.data_edit_user.sites, 'edit') ? undefined : (validation = false)
        this.validateOwnerEdit(this.data_edit_user.blockOwner, 'edit')
          ? undefined
          : (validation = false)

        if (validation) {
          this.questionConfirm('edit', null)
        }
      } else {
        this.modal_window = false
      }
    },
    validateCreateUser() {
      var validation = true
      this.validatePassword(
        this.modal_created.password.value,
        this.modal_created.confirm_password.value,
        'created'
      )
        ? undefined
        : (validation = false)

      console.log(
        'this.validatePassword',
        this.validatePassword(
          this.modal_created.password.value,
          this.modal_created.confirm_password.value,
          'created'
        )
      )
      this.validateUsername(this.modal_created.username.value, 'created')
        ? undefined
        : (validation = false)

      console.log(
        'this.validateUsername',
        this.validateUsername(this.modal_created.username.value, 'created')
      )
      this.validateEmail(this.modal_created.email.value, 'created')
        ? undefined
        : (validation = false)

      console.log(
        'this.validateEmail',
        this.validateEmail(this.modal_created.email.value, 'created')
      )
      this.validateRoles(this.modal_created.selected_roles.items, 'created')
        ? undefined
        : (validation = false)

      console.log(
        'this.validateRoles',
        this.validateRoles(this.modal_created.selected_roles.items, 'created')
      )
      this.validateSites(this.modal_created.selected_sites.items, 'created')
        ? undefined
        : (validation = false)

      console.log(
        'this.validateSites',
        this.validateSites(this.modal_created.selected_sites.items, 'created')
      )
      this.validateOwnerEdit(this.modal_created.blockOwner, 'created')
        ? undefined
        : (validation = false)
      console.log(
        'this.validateOwnerEdit',
        this.validateOwnerEdit(this.modal_created.blockOwner, 'created')
      )
      if (validation) {
        this.questionConfirm('created', null)
      }
    },

    getUserData() {
      this.loading = true
      let all_users_promise = new Promise(
        function(resolve, reject) {
          if (!this.$store.getters.all_users) {
            this.getAllusers(resolve)
          } else {
            resolve(true)
            this.formatGettersforArray()
          }
        }.bind(this)
      )

      let permissions_promise = new Promise(
        function(resolve, reject) {
          if (this.$store.getters.users_permissions.length === 0) {
            this.$store.dispatch('get_users_permissions', {
              vm: this,
              callback: this.getUserPermissionsCallback.bind(this, resolve)
            })
          } else {
            resolve(true)
          }
        }.bind(this)
      )

      Promise.all([all_users_promise]).then(
        function(response) {
          this.loading = false
        }.bind(this)
      )
    },
    getUserPermissionsCallback(resolve, request_status, response) {
      resolve(true)
    },
    getAllusers(resolve) {
      this.$store.dispatch('get_users', {
        roles: this.get_users.role,
        vue: this,
        callback: this.responseGetusers.bind(this, resolve),
        callbackerror: this.callbackerror.bind(this, resolve)
      })
    },
    responseGetusers(resolve, users) {
      let transformArr = []

      for (var key in users.response) {
        transformArr[users.response[key].id] = users.response[key]
      }

      this.$store.commit('response_all_user', transformArr)
      if (resolve) {
        resolve(true)
      }

      this.users_array = JSON.parse(JSON.stringify(users.response))
      this.users_array = this.users_array.map(el => {
        el.showPermissions = false
        return el
      })
    },

    requestEditUser() {
      this.confirm.show = false
      this.modal_window = false
      this.loading = true
      var arr_sites = []
      this.data_edit_user.sites.forEach(function(element) {
        arr_sites.push(element.id)
      })

      this.$store.dispatch('edit_user_request', {
        username: this.data_edit_user.username,
        password: this.data_edit_user.password.change ? this.data_edit_user.password.enter : null,
        email: this.data_edit_user.email,
        roles: this.data_edit_user.roles,
        permissions: this.modal_edit.selected_permissions.items,
        sites: arr_sites,
        owner: this.data_edit_user.blockOwner.active ? this.data_edit_user.owner : '',
        getParamsId: this.data_edit_user.id,
        active: this.data_edit_user.active,
        vue: this,
        callback: this.edit_user_collback,
        callbackerror: this.callbackerror
      })
    },
    edit_user_collback(respons) {
      this.$store.getters.all_users[respons.body.user.id] = respons.body.user
      this.formatGettersforArray()
      this.loading = false
      this.alertNotification('Пользователь изменен')
    },

    dell_user(dellID) {
      this.confirm.show = false
      this.loading = true

      this.$store.dispatch('dell_user', {
        vue: this,
        id: dellID,
        callback: this.dell_user_callback,
        callbackerror: this.callbackerror
      })
    },
    dell_user_callback(data) {
      this.getAllusers(
        function() {
          this.loading = false
        }.bind(this)
      )
      this.alertNotification('Пользователь удален')
    },

    requestCreateUser() {
      this.confirm.show = false
      this.loading = true
      this.create_user_wrapper = false
      var arr_sites = []
      this.modal_created.selected_sites.items.forEach(function(element) {
        arr_sites.push(element.id)
      })

      const tariffs = this.modal_created.selected_sites.items.map(el => ({
        site_id: el.id,
        tariff_id: el.tariff_id || ''
      }))

      this.$store.dispatch('create_user_request', {
        username: this.modal_created.username.value,
        password: this.modal_created.password.value,
        email: this.modal_created.email.value,
        roles: this.modal_created.selected_roles.items,
        permissions: this.modal_created.selected_permissions.items,
        sites: arr_sites,
        identifyOwner: this.modal_created.blockOwner,
        owner: this.modal_created.owner || '',
        vue: this,
        callback: this.create_user_callback,
        callbackerror: this.callbackerror,
        tariffs
      })
    },
    create_user_callback(respons) {
      this.$store.getters.all_users[respons.body.user.id] = respons.body.user
      this.formatGettersforArray()
      this.loading = false
      this.alertNotification('Создан новый пользователь')
      this.modal_created = {
        username: {
          value: '',
          type: '',
          message: ''
        },
        password: {
          value: '',
          type: '',
          message: ''
        },
        confirm_password: {
          value: '',
          type: '',
          message: ''
        },
        email: {
          value: '',
          type: '',
          message: ''
        },
        selected_sites: {
          items: [],
          type: '',
          message: ''
        },
        selected_roles: {
          items: [],
          type: '',
          message: ''
        },
        selected_permissions: {
          items: []
        },
        blockOwner: {
          active: false,
          name: null
        }
      }
    }
  }
}
</script>

<style lang="scss">
.user-page {
  .search-table {
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    .label {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  .custom-close-modal {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-modal-site-table {
    position: relative;
    margin: auto;
    background: white;
    padding: 30px;
    min-width: 800px;
    min-height: 540px;

    .sites-title {
      text-align: center;
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 0px;
    }
    .table-loading {
      min-height: 373px;
    }
  }

  .auth-key {
    height: 200px;
    width: 525px;
    background: #fff;
    margin: auto;
    position: relative;
    padding: 30px;
    overflow: hidden;
    border-radius: 4px;

    .control {
      width: 100%;
      padding: 0 15px;
      margin: 10px 0;
    }
  }

  .auth-key-loading {
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .auth-key-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }

  .table-modal {
    z-index: 50;

    .table-modal-wrapper {
      background: #fff;
      width: 800px;
      height: auto;
      padding: 20px 50px;
      margin: 0 auto;

      .title {
        font-size: 20px;
      }

      // .pagination-next span {
      //   border-right: 3px solid #7957d5;
      //   border-top: 3px solid #7957d5;
      //   transform: rotate(45deg);
      //   height: 15px;
      //   width: 15px;
      //   border-radius: 2px;
      //   margin-right: 5px;
      // }

      // .pagination-previous span {
      //   border-right: 3px solid #7957d5;
      //   border-top: 3px solid #7957d5;
      //   transform: rotate(225deg);
      //   height: 15px;
      //   width: 15px;
      //   border-radius: 2px;
      //   margin-left: 5px;
      // }
      .table-border {
        position: relative;
        margin: 15px 0;
        border: 1px solid #dbdbdb;
        padding: 15px;
        border-radius: 4px;
      }
    }
  }

  .field {
    input {
      width: 350px;
    }

    .select-input {
      width: 350px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .select-table-sites {
    th {
      padding: 15px 0 !important;
    }

    td {
      padding: 15px 0 !important;

      a {
        font-size: 14px;
      }
    }
  }

  .custom-style-label {
    color: #363636;
    font-size: 1rem;
    font-weight: 600;
  }

  .dropdown-content {
    a {
      word-wrap: break-word;
      white-space: inherit;
    }
  }

  .confirm {
    z-index: 42;
    .modal-content {
      justify-content: center;
    }

    .confirm-window {
      position: absolute;
      height: auto;
      width: 300px;
      background: #fff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 4px;
      padding: 35px 20px;
      text-align: center;

      .confirm-window-close {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
      }

      .confirm-wrapper {
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        padding: 0px 10px;
      }

      .confirm-window-description {
        padding: 25px 0;
        display: flex;
        flex-direction: column;

        button {
          margin-top: 15px;
        }
      }

      .confirm-window-buttons {
        padding: 25px 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }

  .alert {
    z-index: 41;
    position: fixed;
    top: 100px;
    right: 50px;
    border: 1px solid #7957d5;
    padding: 20px 25px;
    border-radius: 4px;
    background: #7957d5;
    color: #fff;

    &-close {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .modal .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 100% !important;
  }

  .user-wrapper {
    padding-bottom: 15px;

    .loading {
      padding: 50px 0;
      position: relative;
    }

    .table-wrapper {
      padding-bottom: 15px;
      overflow-x: unset;
    }
  }

  .modal-window {
    width: auto;
    height: auto;
    background: #fff;
    margin: auto;
    border-radius: 4px;
    padding: 20px 50px 50px 50px;
    position: relative;

    .custom-title {
      text-align: center;
      margin-bottom: 1.5rem;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .modal-window-edit {
    border: 1px solid #dbdbdb;
    padding: 15px;
    padding-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .left-part {
      margin-right: 50px;
    }

    .right-part {
      width: 400px;
    }
    .input {
      width: 268px;
    }

    .select-wrapper {
      margin-bottom: 0.5rem;

      .dropdown,
      .dropdown-trigger {
        width: 100%;
      }

      .select-input {
        .selectet-items {
          border: 1px solid #7957d5;
          padding: 3px 5px;
          margin-right: 5px;
          border-radius: 4px;
          font-size: 14px;
          display: inline-block;
          margin-top: 5px;
        }
      }

      .dropdown-menu {
        width: 350px;

        .selected-item {
          background-color: whitesmoke;
          color: #0a0a0a;
        }
      }
    }
    .active-block {
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;

      span {
        color: #363636;
        font-size: 1rem;
        font-weight: 600;
      }

      .switch {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        .control-label {
          padding-left: 0;
        }

        .check {
          margin-left: 10px;
        }
      }
    }

    .field {
      min-height: 90px;

      &.select-wrapper-site {
        min-height: auto;
      }
    }

    .passwword {
      display: flex;
      margin-bottom: 15px;

      label {
        margin-left: 10px;
      }

      span {
        color: #363636;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .create-user-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 4px;
    padding: 15px;
    min-height: 420px;
    position: relative;
    border: 1px solid #dbdbdb;

    form.passwords-block {
      margin-bottom: 0.75rem;
    }
    .select-wrapper {
      margin-bottom: 0.75rem;

      .dropdown,
      .dropdown-trigger {
        width: 100%;
      }
      .select-input {
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        padding: 5px 35px 5px 9px;

        .selectet-items {
          border: 1px solid #7957d5;
          padding: 3px 5px;
          margin-right: 5px;
          border-radius: 4px;
          font-size: 14px;
          display: inline-block;
          margin-top: 5px;
        }
      }
      .select-input::after {
        right: 1.125em;
        z-index: 4;
        border: 3px solid #7957d5;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: ' ';
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: center;
        transform-origin: center;
        width: 0.625em;
      }
      .dropdown-menu {
        width: 350px;

        .selected-item {
          background-color: whitesmoke;
          color: #0a0a0a;
        }
      }
    }
    .request-button {
      width: 120px;
      align-self: end;
    }
    .left-part {
      margin-right: 50px;
    }
    .right-part {
      width: 400px;
    }
    .new-data-blocks {
      display: flex;
      margin-top: 20px;

      .field {
        min-height: 90px;

        &.select-wrapper-site {
          min-height: auto;
        }
      }
    }
  }
  .tabs-icon-key {
    border: 1px solid #23d160;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;

    svg {
      margin-left: 2px;
    }
  }
  .tabs-icon-key:hover {
    background: #23d160;

    path {
      fill: #fff;
    }
  }

  .tabs-icon-edit {
    border: 1px solid #7957d5;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  path {
    transition: 0.3s;
  }
  .tabs-icon-edit:hover {
    background: #7957d5;

    path {
      fill: #fff;
    }
  }
  .tabs-icon-dell {
    border: 1px solid #ff3860;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  .tabs-icon-dell:hover {
    background: #ff3860;

    path {
      fill: #fff;
    }
  }
  .tabs-icon-active {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}
</style>
