<template>
  <div class="admin-page">

    <div class="alert animated none" :class="alert.show ? 'fadeInRight' : ''" v-show="alert.show">
      <div class="alert-close" @click="alert.show = !alert.show">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.88463 5.0001L9.81717 1.06756C10.0615 0.823448 10.0615 0.427359 9.81717 0.183243C9.57285 -0.0610811 9.17718 -0.0610811 8.93285 0.183243L5.00031 4.11578L1.06756 0.183243C0.82324 -0.0610811 0.427568 -0.0610811 0.183243 0.183243C-0.0610811 0.427359 -0.0610811 0.823448 0.183243 1.06756L4.11599 5.0001L0.183243 8.93264C-0.0610811 9.17676 -0.0610811 9.57285 0.183243 9.81697C0.305406 9.93892 0.465509 10 0.625404 10C0.785299 10 0.945402 9.93892 1.06756 9.81676L5.00031 5.88422L8.93285 9.81676C9.05501 9.93892 9.21512 10 9.37501 10C9.53491 10 9.69501 9.93892 9.81717 9.81676C10.0615 9.57264 10.0615 9.17655 9.81717 8.93244L5.88463 5.0001Z" fill="#ffffff"/>
        </svg>
      </div>
      {{ alert.text }}
    </div>

    <div class="loading" v-show="loading">
      <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>
    </div>

    <b-modal :active.sync="confirm.modal_show" class="confirm" :can-cancel="false">
      <div class="confirm-modal">
        <div class="custom-close-modal" @click="confirm.modal_show = !confirm.modal_show">            
          <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z" fill="#2C3236"/>
            </svg>
          </div>
        <div class="confirm-modal-wrapper">
          <div class="confirm-modal-text">
            {{ confirm.text }}
          </div>
          <div class="confirm-modal-button">
           
            <b-button type="is-success" @click="confirm.func(confirm.params_function)">OK</b-button>
            <b-button type="is-danger" @click="confirm.modal_show = false">NO</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="update_admin_container_show" class="table-modal" :can-cancel="false">
      <div class="update-admin">
        <div class="custom-close-modal" @click="update_admin_container_show = !update_admin_container_show">            
          <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z" fill="#2C3236"/>
            </svg>
          </div>
        <div class="update-admin-title">Изменить администратора</div>
        <form>
          <b-tooltip label="Имя администратора может состоять из английских букв в нижнем регистре, цифр и символов подчеркивания."
              size="is-large"
              multilined>
          <b-field :label="'Имя'" :type="field_update_admin.name.type" :message="field_update_admin.name.message">
            <b-input type="text" v-model="update_admin.name"></b-input>
          </b-field>
          </b-tooltip>

          <b-field :label="'Email'" :type="field_update_admin.email.type" :message="field_update_admin.email.message">
            <b-input type="text" v-model="update_admin.email"></b-input>
          </b-field>

          <div class="field">
            <b-switch v-model="update_admin.password_change">Изменить пароль?</b-switch>
          </div>

          <div class="passwword" v-show="update_admin.password_change">
          <b-field :label="'Пароль'" :type="field_update_admin.password.type" :message="field_update_admin.password.message">
            <b-input type="password" v-model="update_admin.password"></b-input>
          </b-field>

          <b-field :label="'Пароль повторно'" :type="field_update_admin.password_confirm.type" :message="field_update_admin.password_confirm.message">
            <b-input type="password" v-model="update_admin.password_confirm"></b-input>
          </b-field>

          </div>
          <div class="field">
            <b-switch v-model="update_admin.active">Активность</b-switch>
          </div>

          <b-button type="is-primary" @click="validUpdateAdmin">Изменить</b-button>
        </form>
      </div>
    </b-modal>
    
    <div class="search-table" v-show="!loading">
      <div class="search-table-button">
        <b-button type="is-primary" outlined @click="table.search.show = !table.search.show">Поиск по таблице</b-button>
      </div>
      <div class="search-table-params" v-show="table.search.show">
        <b-field label="Имя администратора">
          <b-input type="text"
            v-model="table.search.field">
          </b-input>
        </b-field>
      </div>
    </div>
    <b-table :data="filterUsersTable" :checkbox-position="'left'" v-show="!loading"  
      :paginated="true"
      :pagination-simple="true"
      :per-page="table.pagination">
        <template slot-scope="data">

          <b-table-column field="Username" label="Имя">
              {{ data.row.username }}
          </b-table-column>

          <b-table-column field="email" label="Почта">
              {{ data.row.email }}
          </b-table-column>

          <b-table-column field="active" label="Активность" width="40" :centered="true">
            <div v-if="data.row.active">
              <div class="table-icon-active">
                <svg width="25" height="25" viewBox="0 -1 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <mask id="path-1-inside-1" fill="white">
                  <path d="M7.99998 16.0002C12.4183 16.0002 16 12.4185 16 8.00023C16 3.58196 12.4183 0.000244141 7.99998 0.000244141C3.58171 0.000244141 0 3.58196 0 8.00023C0 12.4185 3.58171 16.0002 7.99998 16.0002Z"/>
                  </mask>
                  <path d="M15 8.00023C15 11.8662 11.866 15.0002 7.99998 15.0002V17.0002C12.9705 17.0002 17 12.9708 17 8.00023H15ZM7.99998 15.0002C4.134 15.0002 1 11.8662 1 8.00023H-1C-1 12.9708 3.02943 17.0002 7.99998 17.0002V15.0002ZM1 8.00023C1 4.13424 4.134 1.00024 7.99998 1.00024V-0.999756C3.02943 -0.999756 -1 3.02967 -1 8.00023H1ZM7.99998 1.00024C11.866 1.00024 15 4.13424 15 8.00023H17C17 3.02967 12.9705 -0.999756 7.99998 -0.999756V1.00024Z" fill="#23d160" mask="url(#path-1-inside-1)"/>
                  <path d="M5 7.01587C5.55228 7.01587 6 6.56815 6 6.01587C6 5.46358 5.55228 5.01587 5 5.01587C4.44772 5.01587 4 5.46358 4 6.01587C4 6.56815 4.44772 7.01587 5 7.01587Z" fill="#23d160"/>
                  <path d="M11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7Z" fill="#23d160"/>
                  <path d="M3.93658 10.5659C6.17236 12.8487 9.81941 12.8392 12.0422 10.5422C12.3254 10.2496 11.8792 9.80243 11.5957 10.0956C9.60821 12.1492 6.3836 12.1619 4.38315 10.1194C4.09778 9.82791 3.65147 10.2749 3.93658 10.5659Z" fill="#23d160"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div> 
            <div v-else>
              <div class="table-icon-active">
                <svg width="25" height="25" viewBox="0 -1 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <mask id="path-1-inside-1" fill="white">
                  <path d="M7.99998 16.0002C12.4183 16.0002 16 12.4185 16 8.00023C16 3.58196 12.4183 0.000244141 7.99998 0.000244141C3.58171 0.000244141 0 3.58196 0 8.00023C0 12.4185 3.58171 16.0002 7.99998 16.0002Z"/>
                  </mask>
                  <path d="M15 8.00023C15 11.8662 11.866 15.0002 7.99998 15.0002V17.0002C12.9705 17.0002 17 12.9708 17 8.00023H15ZM7.99998 15.0002C4.134 15.0002 1 11.8662 1 8.00023H-1C-1 12.9708 3.02943 17.0002 7.99998 17.0002V15.0002ZM1 8.00023C1 4.13424 4.134 1.00024 7.99998 1.00024V-0.999756C3.02943 -0.999756 -1 3.02967 -1 8.00023H1ZM7.99998 1.00024C11.866 1.00024 15 4.13424 15 8.00023H17C17 3.02967 12.9705 -0.999756 7.99998 -0.999756V1.00024Z" fill="#ff3860" mask="url(#path-1-inside-1)"/>
                  <path d="M5 7.01587C5.55228 7.01587 6 6.56815 6 6.01587C6 5.46358 5.55228 5.01587 5 5.01587C4.44772 5.01587 4 5.46358 4 6.01587C4 6.56815 4.44772 7.01587 5 7.01587Z" fill="#ff3860"/>
                  <path d="M11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7Z" fill="#ff3860"/>
                  <path d="M12.0412 11.7056C9.80545 9.4228 6.1584 9.43229 3.93562 11.7293C3.6524 12.0219 4.09857 12.4691 4.38216 12.1759C6.3696 10.1222 9.59422 10.1096 11.5947 12.1521C11.88 12.4436 12.3263 11.9966 12.0412 11.7056Z" fill="#ff3860"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div> 
            </div>
          </b-table-column>

          <b-table-column field="edit" label="Редактирование" :centered="true">
            <div class="table-icon-edit" @click="modalUpdateManager(data.row)">
              <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.28606 13.9568C1.28606 13.6806 1.50991 13.4568 1.78606 13.4568H13.9466C14.2227 13.4568 14.4466 13.6806 14.4466 13.9568C14.4466 14.2329 14.2227 14.4568 13.9466 14.4568H1.78606C1.50991 14.4568 1.28606 14.2329 1.28606 13.9568Z" fill="#2C3236"/>
              <path d="M9.56521 2.36876C9.76097 2.174 10.0784 2.174 10.2741 2.36876L11.6908 3.77825C11.8866 3.97301 11.8866 4.28878 11.6908 4.48354L4.10197 12.0337C4.04451 12.0908 3.97394 12.1332 3.89635 12.1572L1.98441 12.7487C1.61101 12.8642 1.25437 12.5288 1.34928 12.1514L1.85516 10.1399C1.8772 10.0522 1.92277 9.97216 1.98699 9.90827L9.56521 2.36876Z" fill="#2C3236"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2911 2.11478L11.8744 0.705294L11.1502 1.42581L12.5669 2.8353L13.2911 2.11478ZM12.2288 0.352647C12.0331 0.157885 11.7157 0.157886 11.5199 0.352647L10.7957 1.07317C10.5999 1.26793 10.5999 1.5837 10.7957 1.77846L12.2124 3.18795C12.4082 3.38271 12.7256 3.38271 12.9213 3.18795L13.6456 2.46743C13.8413 2.27267 13.8413 1.9569 13.6456 1.76214L12.2288 0.352647Z" fill="#2C3236"/>
              </svg>
            </div>
          </b-table-column>

          <b-table-column field="dell" label="Удаление" :centered="true">
            <div class="table-icon-dell" @click="confirmAnswer('dell', data.row)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.41502 7.99983L15.7068 1.70803C16.0977 1.31746 16.0977 0.683746 15.7068 0.293177C15.3159 -0.0977257 14.6829 -0.0977257 14.292 0.293177L8.00017 6.58498L1.70803 0.293177C1.31713 -0.0977257 0.68408 -0.0977257 0.293177 0.293177C-0.0977257 0.683746 -0.0977257 1.31746 0.293177 1.70803L6.58531 7.99983L0.293177 14.2916C-0.0977257 14.6822 -0.0977257 15.3159 0.293177 15.7065C0.488629 15.9016 0.744783 15.9993 1.0006 15.9993C1.25643 15.9993 1.51258 15.9016 1.70803 15.7062L8.00017 9.41435L14.292 15.7062C14.4874 15.9016 14.7436 15.9993 14.9994 15.9993C15.2552 15.9993 15.5114 15.9016 15.7068 15.7062C16.0977 15.3156 16.0977 14.6819 15.7068 14.2913L9.41502 7.99983Z" fill="#2C3236"/>
              </svg>
            </div>
          </b-table-column>

        </template>
    </b-table>

    <div v-show="!loading" class="table-pagination">
      <p>Показать</p>      
      <b-select v-model="table.pagination">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
      </b-select>
    </div>

    <div class="create-admin">
      <div class="create-admin-wrapper">
        <b-button @click="create_admin_container_show = !create_admin_container_show" type="is-primary" outlined>Создать админа</b-button>   
        <div class="create-admin-form" v-show="create_admin_container_show">
          <h2>Создать администратора</h2>
          <form>
            <b-tooltip label="Имя администратора может состоять из английских букв в нижнем регистре, цифр и символов подчеркивания."
                size="is-large"
                multilined>
            <b-field :label="'Имя'" :type="field_create_admin.name.type" :message="field_create_admin.name.message">
              <b-input type="text" v-model="create_admin.name"></b-input>
            </b-field>
            </b-tooltip>

            <b-field :label="'Email'" :type="field_create_admin.email.type" :message="field_create_admin.email.message">
              <b-input type="text" v-model="create_admin.email"></b-input>
            </b-field>

            <b-field :label="'Пароль'" :type="field_create_admin.password.type" :message="field_create_admin.password.message">
              <b-input type="password" v-model="create_admin.password"></b-input>
            </b-field>

            <b-field :label="'Пароль повторно'" :type="field_create_admin.password_confirm.type" :message="field_create_admin.password_confirm.message">
              <b-input type="password" v-model="create_admin.password_confirm"></b-input>
            </b-field>

            <b-button type="is-primary" @click="validAdminCreate">Создать</b-button>
          </form>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import SiteTable from '../components/SiteTable'

export default {
    components: {
    SiteTable
  },
  name: 'CreateAdminUser',
  data () {
    return {
      table: {
        pagination: 10,
        search: {
          show: false,
          field: ''
        }
      },
      confirm: {
        modal_show: false,
        request: null,
        text: '',
        func: null,
        function_params: null
      },
      loading: false,
      alert: {
        show: false,
        text: ''
      },
      update_admin_container_show: false,
      update_admin: {
        name: '',
        email: '',
        password: '',
        password_confirm: '',
        password_change: false,
        active: null,
        id: ''
      },
      field_update_admin: {
        name: {
          type: '',
          message: '',
          initial_value: ''
        },
        email: {
          type: '',
          message: '',
          initial_value: ''
        },
        password: {
          type: '',
          message: ''
        },
        password_confirm: {
          type: '',
          message: ''
        },active: {
          initial_value: ''
        }
      },
      create_admin_container_show: false,
      create_admin: {
        name: '',
        email: '',
        password: '',
        password_confirm: ''
      },
      field_create_admin: {
        name: {
          type: '',
          message: ''
        },
        email: {
          type: '',
          message: ''
        },
        password: {
          type: '',
          message: ''
        },
        password_confirm: {
          type: '',
          message: ''
        }
      },
      dataFilter: [],
    }
  },
  created() {
    if( this.$store.getters.all_admin ) {
      this.dataFilter = this.$store.getters.all_admin
    } else {
      this.getAdmin()
    }
  },
  computed: {
    filterUsersTable: function() {
      var name_re = new RegExp(this.table.search.field),
      tableData = [],
      i = 0

      for (i in this.dataFilter) {
        if( this.dataFilter[i].username.match(name_re) ) {
          tableData.push(this.dataFilter[i])
        }
      }
      return tableData
    } 
  },
  methods: {
    clearFieldAdminCreate() {
      this.create_admin_container_show = false

      this.field_create_admin = {
        name: {
          type: '',
          message: ''
        },
        email: {
          type: '',
          message: ''
        },
        password: {
          type: '',
          message: ''
        },
        password_confirm: {
          type: '',
          message: ''
        }
      }

      this.create_admin = {
        name: '',
        email: '',
        password: '',
        password_confirm: '',
        active: null,
        id: ''
      }
    },
    validAdminCreate() {
      var valid = true
      if ( !this.validatePassword( this.create_admin.password, this.create_admin.password_confirm) ) {
        this.field_create_admin.password.type = 'is-danger'
        this.field_create_admin.password_confirm.type = 'is-danger' 
        this.field_create_admin.password_confirm.message = 'Пароль не верный'
        valid = false
      }
      
      if( !this.validateName(this.create_admin.name) ) {
        this.field_create_admin.name.type = 'is-danger'
        this.field_create_admin.name.message = 'Не валидное имя'
        valid = false
      } 

      if ( !this.validateEmail( this.create_admin.email )) {
        this.field_create_admin.email.type = 'is-danger'
        this.field_create_admin.email.message = 'Не валидная почта'
        valid = false
      }

      if( valid ) {
        this.confirmAnswer('create', this.create_admin)
      }
    },
    validatePassword( password, password_confirm) {
      return password.length > 0 && password_confirm.length > 0 && password == password_confirm
    },
    validateName(name) {
      return ( name.length > 0 && !!name.match(this.$store.getters.regex.username) && (name == name.toLowerCase()) )
    },
    validateEmail( email ){
      return (email.length > 0 && !!email.match(this.$store.getters.regex.email))
    },
    getAdmin() {
      this.loading = true
      this.$store.dispatch('getAdmins', {
        vue: this,
        callback: this.getAdminCallback,
        callbackerror: this.callbackerror
      })
    },
    confirmAnswer(mode, params) {
      this.confirm.modal_show = true

      if( mode == 'dell') {
        this.confirm.text = 'Вы уверены что хотите удалить адимистратора?'
        this.confirm.func = this.dellAdmin
        this.confirm.params_function = params

      } else if( mode == 'update') {
        this.confirm.text = 'Вы уверены что хотите изменить адимистратора?'
        this.confirm.func = this.updateAdmin
        this.confirm.params_function = params

      } else if( mode == 'create') {
        this.confirm.text = 'Вы уверены что хотите создать адимистратора?'
        this.confirm.func = this.createAdmin
        this.confirm.params_function = params
      }
    },
    modalUpdateManager(data){
      this.update_admin_container_show = true

      this.update_admin = {
        name: data.username,
        email: data.email,
        password: '',
        password_confirm: '',
        password_change: false,
        active: data.active,
        id: data.id
      },

      this.field_update_admin= {
        name: {
          type: '',
          message: '',
          initial_value: data.username
        },
        email: {
          type: '',
          message: '',
          initial_value: data.email
        },
        password: {
          type: '',
          message: ''
        },
        password_confirm: {
          type: '',
          message: ''
        },active: {
          initial_value: data.active
        }
      }
    },
    validUpdateAdmin(){
      if( this.field_update_admin.name.initial_value != this.update_admin.name ||
        this.field_update_admin.email.initial_value != this.update_admin.email || 
        this.field_update_admin.active.initial_value != this.update_admin.active || 
        this.update_admin.password_change) {

        var valid = true

        if( this.update_admin.password_change) {
          if ( !this.validatePassword( this.update_admin.password, this.update_admin.password_confirm) ) {
            this.field_update_admin.password.type = 'is-danger'
            this.field_update_admin.password_confirm.type = 'is-danger' 
            this.field_update_admin.password_confirm.message = 'Пароль не верный'
            valid = false
          }
        }
    
        if( !this.validateName(this.update_admin.name) ) {
          this.field_update_admin.name.type = 'is-danger'
          this.field_update_admin.name.message = 'Не валидное имя'
          valid = false
        } 

        if ( !this.validateEmail( this.update_admin.email )) {
          this.field_update_admin.email.type = 'is-danger'
          this.field_update_admin.email.message = 'Не валидная почта'
          valid = false
        }

        if( valid ) {
          this.confirmAnswer('update', this.update_admin)
        }
      } else {
        this.update_admin_container_show = false
      }
    },
    getAdminCallback(response){
      this.dataFilter = []
      response.response.body.users.forEach(element => {
        if (element.permissions.length > 0) {
          var superadmin = true 
          element.permissions.forEach(permissions => {
            if( permissions == 'superadmin') {
              superadmin = false
            }
          })
          if( superadmin ) { 
            this.dataFilter.push(element)
          }
        } else {
          this.dataFilter.push(element)
        }
      })
      this.$store.commit('response_all_admin', this.dataFilter)
      this.loading = false
    },
    updateAdmin(data){
      this.update_admin_container_show = false
      this.confirm.modal_show = false
      this.loading = true
      this.$store.dispatch('updateAdmin', {
        data: data,
        vue: this,
        callback: this.updateAdminCallback,
        callbackerror: this.callbackerror
      })
    },
    updateAdminCallback() {
      this.alertNotification('Администратор изменен')
      this.getAdmin()  
    },
    createAdmin(data){
      this.clearFieldAdminCreate()
      this.confirm.modal_show = false
      this.loading = true
      this.$store.dispatch('createAdmin', {
        data: data,
        vue: this,
        callback: this.createAdminCallback,
        callbackerror: this.callbackerror
      })
    },
    createAdminCallback() {
      this.alertNotification('Администратор создан')
      this.getAdmin()  
    },
    dellAdmin(data){
      this.confirm.modal_show = false
      this.loading = true
      this.$store.dispatch('dellAdmin', {
        data: data,
        vue: this,
        callback: this.dellAdminCallback,
        callbackerror: this.callbackerror
      })
    },
    dellAdminCallback() {
      this.alertNotification('Администратор удален')
      this.getAdmin()  
    },
    callbackerror(error){
      this.alertNotification(error)
      console.log('callbackerror', error)
      this.loading = false
    },
    alertNotification(text) {
      this.alert.show = true
      this.alert.text = text

      setTimeout(() => {
        this.alert.show = false
      }, 3500), this;
    },
  }
}
</script>

<style lang="scss">
.admin-page {

  .custom-close-modal {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alert {
    z-index: 41;
    position: fixed;
    top: 100px;
    right: 50px;
    border: 1px solid #7957d5;
    padding: 20px 25px;
    border-radius: 4px;
    background: #7957d5;
    color: #fff;

    &-close {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .confirm{
    z-index: 41;
  }

  .confirm-modal {
    height: 300px;
    width: 300px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    padding: 30px;
    position: relative;
  }

  .confirm-modal-wrapper {
    padding: 20px;
    border: 1px solid #dbdbdb;
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 4px;
  }

  .confirm-modal-text {
    text-align: center;
  } 

  .confirm-modal-button {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .search-table {
    width: 350px;
    padding: 20px 15px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;

    .field {
      margin: 10px 0;
    }
  }
  
  .table-wrapper {
    margin: 30px 0;
  }

  .table-pagination {
    display: flex;
    align-items: center;
    margin-top: -60px;

    p {
      padding-right: 15px;
    }
  }

  .pagination-next span {
        border-right: 3px solid #7957d5;
        border-top: 3px solid #7957d5;
        transform: rotate(45deg);
        height: 15px;
        width: 15px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .pagination-previous span {
        border-right: 3px solid #7957d5;
        border-top: 3px solid #7957d5;
        transform: rotate(225deg);
        height: 15px;
        width: 15px;
        border-radius: 2px;
        margin-left: 5px;
      }
  
  .table-icon-active {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .table-icon-edit {
    border: 1px solid #7957d5;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  path {
    transition: 0.3s;
  }
  .table-icon-edit:hover {
    background: #7957d5;

    path {
      fill: #fff;
    }
  }

  .table-icon-dell {
    border: 1px solid #ff3860;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
  }
  .table-icon-dell:hover {
    background: #ff3860;

    path {
      fill: #fff;
    }
  }

  .create-admin {
    margin: 20px 0;
  }

  .create-admin-wrapper {
    width: 350px;
    padding: 20px 15px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;

    span {
      width: 100%;
    }

    .field {
      width: 100%;
      margin: 10px 0;
    }
  }

  .create-admin-form {
    margin-top: 25px;

    button {
      margin-top: 25px;
    }
  }

  .update-admin {
    height: auto;
    width: 500px;
    background: #fff;
    border-radius: 4px;
    margin: 0 auto;
    padding: 20px 50px 50px 50px;
    position: relative;

    form {
      padding: 15px;
      border: 1px solid #dbdbdb;
    }
    
    span {
      width: 100%;
    }

    button {
      margin-top: 25px;
    }

    .field {
      width: 100%;
      margin: 10px 0;
    }
  }

  .update-admin-title {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 20px;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

}


</style>